export interface __PaginationType<T> {
    num_of_pages: number;
    count: number;
    data: T[];
}

export interface __ResponseType<T> {
    data: T;
    status: number;
}

export enum __LanguageEnum {
    en = 'en',
    fa = 'fa',
}

export enum __ServerHeadersEnum {
    lang = 'tehranto-website-lang',
    path = 'tehranto-website-path',
    url = 'tehranto-website-url',
}

export enum __CookiesEnum {
    lang = 'tehrantoLang',
    accessToken = 'tehrantoAccessToken',
}

export enum __BPEnum {
    XS = 391,
    SM = 576,
    MD = 768,
    LG = 992,
    XL = 1200,
    XXL = 1600,
}
export interface __JWTTokenType {
    access: string;
    refresh: string;
    expire_date: string;
}

export type __PageBuilderContentType = {
    serialize?: string;
    maxWidth?: string;
    backgroundColor?: string;
    showHeader?: boolean;
    showFooter?: boolean;
};
