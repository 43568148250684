export const __ComponentsLang = {
    see_more: 'مشاهده بیشتر',
    buy_text: 'خرید',
    sale: 'فروش',
    share: 'اشتراک گذاری',
    terms_of_use: 'شرایط و توضیحات',
    description: 'توضیحات',
    locations: 'مناطق ارائه خدمات',
    customers_comments: 'نظرات مشتریان',
    comments: 'نظرات',
    more: 'بیشتر',
    serviceDetail: 'جزئیات خدمت',
    brandDetail: 'جزئیات برند',
    choose_to_buy: 'استفاده از',
    uses: 'موارد استفاده',
    ok: 'تایید',
    close: 'بستن',
    partnerCompanies: 'شرکت های همکار',
    users: 'کاربران سایت',
    customerSatisfaction: 'خریدهای موفق',
    refund: 'بازگشت وجه',
    errorMessage: 'درخواست با خطا مواجه شد.',
    noComment: 'نظری برای این خدمت ثبت نشده است.',
    copy_alert: 'لینک خدمت با موفقیت در کلیپ بورد شما ذخیره شد.',
    selected: 'انتخاب شده',
    initializeError: 'انتخاب با خطا مواجه شد.',
    initializeSuccess: 'پیش سفارش شما با موفقیت ثبت شد',
    initialDescription: [
        'شما می توانید بعد از نهایی کردن خرید خود از',
        'brand',
        '، با ثبت مدارک خرید خود در قسمت ',
        'link',
        ' در پروفایل کاربری از',
        'benefits',
        'سایت تهرانتو کلاب بهره مند شوید',
    ],
    initialWarning:
        'در نظر داشته باشید این مبلغ جدا از هر تخفیفی می باشد که شرکت ها و سایت ها روی خدمات خود ممکن هست برای مشتری در نظر بگیرند!',
    initialLink: 'خریدهای من',
    address: 'آدرس:',
    phoneNumber: 'شماره های تماس:',
    title_desc: 'آنچه با انتخاب خرید این سرویس از تهرانتو دریافت خواهید کرد:',
    refund2: 'دلار بازگشت وجه',
    refund_percent: 'درصد بازگشت وجه',
    confirm: 'تایید',
    download: 'چاپ',
    useCondition: 'شرایط استفاده',
    hiring: (e: number) => [`${e ?? 'بدون'} آگهی`, `استخدام`],
    services: (e: number) => [`${e ?? 'بدون'} سرویس`, `تخفیف های شهر`],
    rank: (e: number) => [`${e ?? 'بدون'} رای`, `برترین ها`],
    locationSearchErr: 'جستجوی موقعیت‌ها با خطا مواجه شد. لطفا بعدا تلاش کنید.',
    startType: 'شروع به تایپ کنید',
    startTypeDesc: 'جهت یافتن شهر مورد نظرتان نام شهر را تایپ کنید',
};
