import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import styles from './animatedText.module.scss';
import line from 'assets/images/landing2/line.svg';
const AnimatedTextComp = (props: {
  prefix?: string;
  textIndex: number;
  texts: string[];
  className?: string;
  suffix?: string;
}) => {
  const delay = 40; // milliseconds
  const [text, setText] = useState<{
    text: string;
    index: number;
    textIndex: number;
    status: 'done' | 'decreasing' | 'increasing';
  }>({
    text: props.texts[0],
    index: props.texts[0].length,
    textIndex: props.textIndex,
    status: 'done'
  });
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const changeTab = () => {
    timeout.current = setTimeout(() => {
      const newText = props.texts[props.textIndex];
      setText(prevText => {
        if (prevText.status === 'done' && props.textIndex !== prevText.textIndex || prevText.status === 'decreasing' && prevText.index > 0) {
          return {
            text: prevText.text.slice(0, prevText.index - 1),
            status: 'decreasing',
            index: prevText.index - 2 > 1 ? prevText.index - 2 : 0,
            textIndex: prevText.textIndex
          };
        } else if (prevText.status === 'decreasing' && prevText.index === 0 || prevText.status === 'increasing' && prevText.index < newText.length) {
          return {
            text: newText.slice(0, prevText.index + 1),
            status: 'increasing',
            index: prevText.index + 1,
            textIndex: props.textIndex
          };
        } else return {
          text: newText,
          status: 'done',
          index: newText.length,
          textIndex: props.textIndex
        };
      });
    }, delay);
  };
  useEffect(() => changeTab(), [text.index, props.textIndex]);
  return <h2 className={`t-h2 t-900 t-dark1 text-center ${props.className ?? ''} ${styles['animated-text']}`} data-sentry-component="AnimatedTextComp" data-sentry-source-file="animatedText.index.tsx">
            <Image className={styles['img-1']} src={line} width={16} height={17} alt="line" data-sentry-element="Image" data-sentry-source-file="animatedText.index.tsx" />
            <span className="t-h2 t-900">{props.prefix}</span>{' '}
            <span className={`${styles['animation']}`}>
                {text.text.split('#').filter(i => i !== '#' && i.length).map((subText, index) => {
        if (index % 2 === 0) return <span key={index}>{subText} </span>;else return <span className={'t-primary-i'} key={index}>
                                    {subText}{' '}
                                </span>;
      })}
            </span>
            <span className="t-h2 t-900">{props.suffix}</span>
            <Image className={styles['img-2']} src={line} width={16} height={17} alt="line" data-sentry-element="Image" data-sentry-source-file="animatedText.index.tsx" />
        </h2>;
};
export default AnimatedTextComp;