'use client';

import { EventType } from '@/types';
import styles from './eventCard.module.scss';
import { HiUsers } from 'react-icons/hi';
import Image from 'next/image';
import { useContext } from 'react';
import { GlobalContext } from '@/context';
import Link from 'next/link';
import { PATH } from '@/data';
import { DateString } from '@/scripts';
function EventCardComp({
  item,
  className
}: {
  item: EventType;
  className?: string;
}) {
  const lang = useContext(GlobalContext).langText.eventCard;
  const langText = useContext(GlobalContext).langText.lang;
  const mainImg = item.gallery?.length ? item.gallery[0] : undefined;
  return <Link href={PATH(langText).events.detail(item.slug)} target="_blank" className={className ?? ''} data-sentry-element="Link" data-sentry-component="EventCardComp" data-sentry-source-file="eventCard.index.tsx">
            <div className={`${styles['card-layout']}`}>
                <div className={`${styles['image']}`}>
                    {mainImg ? <Image src={mainImg.file} alt={mainImg.alt ?? ''} width={328} height={200} /> : null}
                    <span className="center-content t-p2 t-400">{item.category?.title}</span>
                </div>
                <div className="mh-1">
                    <h4 className="t-h4 t-dark1 t-700 mb-2">{item.title}</h4>
                    <p className="t-dark2 t-400 t-p1">
                        {item.location?.name} -{' '}
                        {item.event_date ? DateString(item.event_date, '{Y:4}/{M:2}/{D:2}', 'en-US') : null}
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="t-dark3 t-400 t-p1 center-content-x gap-8">
                            <HiUsers size={18} data-sentry-element="HiUsers" data-sentry-source-file="eventCard.index.tsx" /> {(item.capacity ?? 0) - (item.approved_participant_count ?? 0)} /
                            {item.capacity}
                        </p>
                        <p className="t-500 t-primary t-p1">{item.price ? `${lang.cost} ${item.price}$` : lang.free}</p>
                    </div>
                </div>
            </div>
        </Link>;
}
export default EventCardComp;