'use client';

import { Input, Pagination, Skeleton } from 'antd';
import styles from './bests.module.scss';
import { COLORS } from '@/utilities';
import CategoryListComp from './categoryList/categoryList.index';
import { BrandRankingType, CategoryType, CategoryUsageEnum, PaginationType, ResponseType } from '@/types';
import { useContext, useEffect, useState } from 'react';
import BestCardComp from './bestCard/bestCard.index';
import { FaStar } from 'react-icons/fa6';
import Image from 'next/image';
import bgSVG from 'assets/images/landing2/bestSVG.svg';
import { GlobalContext } from '@/context';
import { RestAPI } from '@/scripts';
import { API } from '@/data';
import AuthModalComp from '@/components/authModal/authModal.index';
const pageSize = 10;
function BestsComp() {
  const lang = useContext(GlobalContext).langText;
  const langText = lang.bests;
  const [rankings, setRankings] = useState<ResponseType<PaginationType<BrandRankingType>>>();
  const [selectedCat, setSelectedCat] = useState<Partial<CategoryType>>();
  const [categories, setCategories] = useState<CategoryType[]>();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [pause, setPause] = useState(false);
  useEffect(() => {
    RestAPI.get<PaginationType<CategoryType>>(API.categories.list, {
      lang: lang.lang,
      page_size: 100,
      not_usage: CategoryUsageEnum.offers
    }).then(res => {
      setCategories(res.data.data);
    });
  }, []);
  useEffect(() => {
    if (selectedCat) {
      setRankings(undefined);
      RestAPI.get<PaginationType<BrandRankingType>>(API.ranking.brands, {
        page_size: pageSize,
        page,
        brand__lang: lang.lang,
        title__icontains: search,
        ordering: ['-is_ad', '-score'],
        approval_status: 1,
        category: selectedCat.id
      }).then(setRankings).catch(setRankings);
    }
  }, [search, selectedCat, page]);
  if (!categories?.length) return null;
  return <div className={`${styles['bests-container']} main-content`} data-sentry-component="BestsComp" data-sentry-source-file="bests.index.tsx">
            <AuthModalComp data-sentry-element="AuthModalComp" data-sentry-source-file="bests.index.tsx" />
            <div className={`${styles['bests-top-section']} center-content flex-column pv-8 ph-4 mb-8`}>
                <Image src={bgSVG} width={1120} height={450} alt="svg" className={`${styles['best-svg']}`} data-sentry-element="Image" data-sentry-source-file="bests.index.tsx" />
                <div className={`${styles['stars']} center-content mb-3`}>
                    <FaStar color={COLORS.primary} size={26} style={{
          marginTop: '12px'
        }} data-sentry-element="FaStar" data-sentry-source-file="bests.index.tsx" />
                    <FaStar color={COLORS.primary} size={33} style={{
          marginTop: '6px'
        }} data-sentry-element="FaStar" data-sentry-source-file="bests.index.tsx" />
                    <FaStar color={COLORS.primary} size={40} data-sentry-element="FaStar" data-sentry-source-file="bests.index.tsx" />
                    <FaStar color={COLORS.primary} size={33} style={{
          marginTop: '6px'
        }} data-sentry-element="FaStar" data-sentry-source-file="bests.index.tsx" />
                    <FaStar color={COLORS.primary} size={26} style={{
          marginTop: '12px'
        }} data-sentry-element="FaStar" data-sentry-source-file="bests.index.tsx" />
                </div>
                <h1 className="t-h1 t-900 mb-5">{langText.title}</h1>
                <p className="t-p1 t-400 t-dark3 mb-5 text-center">{langText.desc}</p>
                <Input.Search className={`${styles['search-input']}`} placeholder={langText.placeholder} onSearch={e => setSearch(e)} data-sentry-element="unknown" data-sentry-source-file="bests.index.tsx" />
            </div>
            <div className={`${styles['root-rank-hover-area']} ${pause ? styles['pause'] : ''}`}>
                {categories ? <CategoryListComp brands={rankings} categorySelect={setSelectedCat} categories={categories} /> : null}
                <div className={`${styles['bests-layout']} mt-5`}>
                    {/* bestBrands()
                     .filter((item) => item.ads)
                     .map((brand) => (
                        <BestCardComp key={brand.id} data={brand} />
                     )) */}
                    {/* <div className={`${styles['best']} mv-1 pt-2 pb-1 ph-1`}>
                        <h4 className="t-h4 t-700 mh-2 mb-2">{langText.bests}</h4>
                        {bestBrands()
                            // .filter((item) => !item.ads)
                            .sort((a, b) => (a.rank ?? bestBrands.length) - (b.rank ?? bestBrands.length))
                            .splice(0, 5)
                            .map((brand) => (
                                <BestCardComp key={brand.id} data={brand} />
                            ))}
                     </div> */}
                    {rankings?.data.data.map(brand => <BestCardComp key={brand.id} data={brand} pauseAnimation={() => setPause(true)} resumeAnimation={() => setPause(false)} />)}
                    {rankings === undefined ? [...Array(10)].map((item, index) => <Skeleton.Node key={index} rootClassName={styles['skeleton']} active>
                                  <span />
                              </Skeleton.Node>) : null}
                </div>
                <div className={`${styles['pagination-layout']} d-flex center-content mt-5 mb-10`}>
                    <div className={`${styles['pagination']} d-flex center-content`}>
                        <Pagination size="small" pageSize={pageSize} showSizeChanger={false} hideOnSinglePage onChange={e => setPage(e)} total={rankings?.data.count} data-sentry-element="Pagination" data-sentry-source-file="bests.index.tsx" />
                    </div>
                </div>
            </div>
        </div>;
}
export default BestsComp;