export const __HiringLang = {
    filters: 'فیلتر ها',
    delete: 'حذف',
    newest: 'جدیدترین',
    result: 'نتیجه',
    ordering: 'مرتب‌سازی',
    mostRelated: 'مرتبط ترین',
    emptyHiring: 'موقعیت شغلی در این برند یافت نشد.',
    jobSearch: 'عنوان شغلی....',
    jobCategory: 'گروه شغلی',
    jobType: 'نوع قرارداد',
    remote: 'دورکاری',
    inPerson: 'حضوری',
    hybrid: 'ترکیبی',
    time: 'زمان',
    fullTime: 'تمام وقت',
    halfTime: 'نیمه وقت',
    partTime: 'پاره وقت',
    experience: 'میزان تجریه',
    beginner: 'کارآموز',
    intern: 'تازه‌کار',
    twoToFiveYears: '۲ تا ۵ سال',
    fiveToTenYears: '۵ تا ۱۰ سال',
    moreThanTenYears: 'بیش از ۱۰ سال',
    gender: 'جنسیت',
    male: 'آقا',
    female: 'خانم',
    salary: 'حقوق',
    agreement: 'توافقی',
    range: 'بازه',
    salaryType: 'مدل پرداخت',
    hourly: 'ساعتی',
    monthly: 'ماهانه',
    daily: 'روزانه',
    applyFilter: 'اعمال فیلتر',
    sort: 'مرتب‌سازی',
    features: 'ویژگی ها',
    requirements: 'نیازمندی ها',
    description: 'شرح موقعیت شغلی',
    benefits: 'مزایا',
    about: 'درباره شرکت',
    bookmark: 'نشان کردن',
    sendResume: 'ارسال رزومه',
};
