import { __FindingFriendDetailLang } from '@/tehranto/panel/dating/findingFriendDetail/findingFriendDetail.lang';
import { __FindingFriendLang } from '@/tehranto/panel/dating/findingFriend/findingFriend.lang';
import { __OrderLang } from 'tehranto/panel/order/order.lang';
import { __ReviewCardLang } from 'components/reviewCard/reviewCard.lang';
import { __AboutUsLang } from 'tehranto/aboutUs/aboutUs.lang';
import { __LandingLang } from 'tehranto/landing/landing.lang';
import { __SignUpLang } from 'tehranto/signup/signup.lang';
import { headerLang } from 'components/header/header.lang';
import { __SliderLang } from 'components/slider/slider.lang';
import { __NotFoundLang } from 'tehranto/404/404.lang';
import { __ErrorLang } from 'tehranto/500/500.lang';
import { __LoadingLang } from 'tehranto/loading/loading.lang';
import { __LoginLang } from 'tehranto/login/login.lang';
import { LanguageEnum } from 'types';
import { __ComponentsLang } from '@/components/components.lang';
import { __CategoryListLang } from '@/tehranto/landing/categoryList/categoryList.lang';
import { __BrandDetailLang } from '@/tehranto/brandDetail/brandDetail.lang';
import { __ServiceFilterLang } from '@/components/serviceFilter/serviceFilter.lang';
import { __BrandInfoLang } from '@/tehranto/brandDetail/brandInfo/brandInfo.lang';
import { __ServiceDetailLang } from '@/tehranto/serviceDetail/serviceDetail.lang';
import { __ServiceInfo } from '@/tehranto/serviceDetail/serviceInfo/serviceInfo.lang';
import { __ServicesListLang } from '@/tehranto/servicesList/servicesList.lang';
import { __PanelMenuLang } from '@/tehranto/panel/panelMenu/panelMenu.lang';
import { __ChangePasswordLang } from '@/tehranto/panel/profile/changePasswordModal/changePassword.lang';
import { __ProfileLang } from '@/tehranto/panel/profile/profile.lang';
import { __OrderDetailLang } from '@/tehranto/panel/orderDetail/orderDetail.lang';
import { __EventListLang } from '@/tehranto/eventsList/eventsList.lang';
import { __EventCardLang } from '@/components/eventCard/eventCard.lang';
import { __EventsFilterLang } from '@/tehranto/eventsList/eventsFilter/eventsFilter.lang';
import { __MyHostsLang } from '@/tehranto/panel/events/myHosts/myHosts.lang';
import { __MyParticipatesLang } from '@/tehranto/panel/events/myParticipates/myParticipates.lang';
import { __CreateEventLang } from '@/tehranto/panel/events/eventForm/eventForm.lang';
import { bottomNavigationLang } from '@/components/bottomNavigation/bottomNavigation.lang';
import { __SearchLang } from '@/tehranto/search/search.lang';
import { __LoginOTPLang } from '@/tehranto/loginOTP/login.lang';
import { __EventDetailLang as __PanelEventDetailLang } from '@/tehranto/panel/events/eventDetail/eventDetail.lang';
import { __EventDetailLang } from '@/tehranto/eventDetail/eventDetail.lang';
import { __ModalLang } from '@/tehranto/eventDetail/eventInfo/modal/modal.lang';
import { __CategoriesLang } from '@/tehranto/categories/categories.lang';
import { __LandingLang2 } from '@/tehranto/landing2/landing.lang';
import { __ServiceNavigationLang } from '@/components/serviceNavigation/serviceNavigation.lang';
import { __MbtiTestLang } from '@/tehranto/panel/dating/mbtiTest/mbti.lang';
import { __InitRoomAndRoomMateLang } from '@/tehranto/panel/housing/selectForm/init.lang';
import { __AskForHostLang } from '@/tehranto/panel/housing/askForHost/askForHost.lang';
import { __AskForHostFormLang } from '@/tehranto/panel/housing/askForHost/askForHostForm/askForHostForm.lang';
import { __AskForHomeFormLang } from '@/tehranto/panel/housing/askForHome/askForHomeForm/askForHomeForm.lang';
import { __AskForHomeLang } from '@/tehranto/panel/housing/askForHome/askForHome.lang';
import { __AdForHostLang } from '@/tehranto/panel/housing/adForHost/adForHost.lang';
import { __AdForHostFormLang } from '@/tehranto/panel/housing/adForHost/adForHostForm/adForHostForm.lang';
import { __AdForHomeLang } from '@/tehranto/panel/housing/adForHome/adForHome.lang';
import { __AdForHomeFormLang } from '@/tehranto/panel/housing/adForHome/adForHomeForm/adForHomeForm.lang';
import { __DatingLang } from '@/tehranto/dating/dating.lang';
import { __HousesListLang } from '@/tehranto/housesList/housesList.lang';
import { __TenantFilterLang } from '@/tehranto/roommatesList/filter/filter.lang';
import { __RoommatesListLang } from '@/tehranto/roommatesList/roommatesList.lang';
import { __HousesDetailLang } from '@/tehranto/housesDetail/housesDetail.lang';
import { __RoommatesDetailLang } from '@/tehranto/roommatesDetail/roommatesDetail.lang';
import { __HomeAdsLang } from '@/tehranto/panel/housing/homeAdsList/homeAdsList.lang';
import { __HomeRequestLang } from '@/tehranto/panel/housing/homeRequest/homeRequest.lang';
import { __HomeAdDetailLang } from '@/tehranto/panel/housing/homeAdDetail/homeAdDetail.lang';
import { __BlogLang } from '@/tehranto/blog/blog.lang';
import { __TermsLang } from '@/tehranto/terms/terms.lang';
import { __PageBuilderLang } from '@/tehranto/customPage/moreComps/moreComps.lang';
import { __CustomPageLang } from '@/tehranto/customPage/customPage.lang';
import { __BestsLang } from '@/tehranto/landing2/bests/bests.lang';
import { __HouseFilterLang } from '@/tehranto/housesList/filter/filter.lang';
import { __YTCampaignLang } from '@/tehranto/ytCampaign/ytCampaign.lang';

export const TehrantoLangFA = {
    lang: LanguageEnum.fa,
    landing: __LandingLang,
    notFound: __NotFoundLang,
    error: __ErrorLang,
    header: headerLang,
    slider: __SliderLang,
    loading: __LoadingLang,
    login: __LoginLang,
    loginOTP: __LoginOTPLang,
    signup: __SignUpLang,
    components: __ComponentsLang,
    aboutUs: __AboutUsLang,
    categoryList: __CategoryListLang,
    brandDetail: __BrandDetailLang,
    serviceFilter: __ServiceFilterLang,
    reviewCard: __ReviewCardLang,
    brandInfo: __BrandInfoLang,
    serviceList: __ServicesListLang,
    serviceDetail: __ServiceDetailLang,
    serviceInfo: __ServiceInfo,
    panelMenu: __PanelMenuLang,
    changePassword: __ChangePasswordLang,
    panelProfile: __ProfileLang,
    panelOrders: __OrderLang,
    panelOrderDetail: __OrderDetailLang,
    eventList: __EventListLang,
    eventCard: __EventCardLang,
    eventsFilter: __EventsFilterLang,
    myHosts: __MyHostsLang,
    MyParticipates: __MyParticipatesLang,
    createEvent: __CreateEventLang,
    bottomNavigation: bottomNavigationLang,
    search: __SearchLang,
    panelEventDetail: __PanelEventDetailLang,
    eventDetail: __EventDetailLang,
    modalEventDetail: __ModalLang,
    categories: __CategoriesLang,
    landing2: __LandingLang2,
    serviceNavigation: __ServiceNavigationLang,
    mbtiTest: __MbtiTestLang,
    findingFriendDetail: __FindingFriendDetailLang,
    findingFriend: __FindingFriendLang,
    roomAndRoomMateInit: __InitRoomAndRoomMateLang,
    askForHost: __AskForHostLang,
    askForHostForm: __AskForHostFormLang,
    askForHome: __AskForHomeLang,
    askForHomeForm: __AskForHomeFormLang,
    addForHost: __AdForHostLang,
    adForHostForm: __AdForHostFormLang,
    adForHome: __AdForHomeLang,
    adForHomeForm: __AdForHomeFormLang,
    dating: __DatingLang,
    housesList: __HousesListLang,
    tenantFilter: __TenantFilterLang,
    houseFilter: __HouseFilterLang,
    roommatesList: __RoommatesListLang,
    housesDetail: __HousesDetailLang,
    roommatesDetail: __RoommatesDetailLang,
    terms: __TermsLang,
    blog: __BlogLang,
    customPage: __CustomPageLang,
    pageBuilder: __PageBuilderLang,
    general: {
        confirm: 'تایید',
        cancel: 'لغو',
        uploading: 'درحال بارگذاری',
        error: 'خطایی رخ داده است، لطفا مجددا تلاش نمایید',
    },
    homeAds: __HomeAdsLang,
    homeRequest: __HomeRequestLang,
    homeAdDetail: __HomeAdDetailLang,
    bests: __BestsLang,
    YTCampaignLang: __YTCampaignLang,
};
