import { AppLinkComp } from '@/components';
import styles from '../header.module.scss';
import { usePathname } from 'next/navigation';
import { PATH } from '@/data';
import { useContext } from 'react';
import { GlobalContext } from '@/context';
// import { ServiceTypeEnum } from '@/types';
import { Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { TbChevronDown, TbChevronLeft } from 'react-icons/tb';
interface LinkType {
  title: string;
  href?: string;
  key: number | string;
  children?: LinkType[];
}
export default function HeaderLinksComp() {
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.header;
  const language = langText.lang;
  const pathname = usePathname();
  const isPage = (path: string) => pathname.split('/').join('').split('?')[0] === path.split('/').join('');
  // const isLink = (item?: ServiceTypeEnum) =>
  //     !(
  //         isPage(PATH(language).home()) ||
  //         (isPage(PATH(language).services.index()) && item === ServiceTypeEnum.offer) ||
  //         (isPage(PATH(language).dating) && item === ServiceTypeEnum.dating && profile)
  //     );

  const links2: LinkType[] = [{
    title: lang.home,
    href: PATH(language).home(),
    key: 'home'
  }, {
    title: lang.verticals,
    children: [{
      title: lang.services,
      href: PATH(language).services.index(),
      key: 'services'
    }, {
      title: lang.hiring,
      href: PATH(language).hiring.index(),
      key: 'hiring'
    }, {
      title: lang.housing,
      children: [{
        title: lang.houses,
        href: PATH(language).housing.houses(),
        key: 'houses'
      }, {
        title: lang.tenants,
        href: PATH(language).housing.roommates(),
        key: 'roommates'
      }],
      key: 'housing'
    }, {
      title: lang.events,
      href: PATH(language).events.index(),
      key: 'events'
    }],
    key: 'verticals'
  }, {
    title: lang.specials,
    children: [{
      title: lang.marketing,
      key: 'marketing',
      href: PATH(language).extraPage('marketing')
    }, {
      title: lang.migration,
      key: 'migration',
      href: PATH(language).extraPage('migration')
    }, {
      title: lang.newComers,
      key: 'newComers',
      href: PATH(language).extraPage('new-comers')
    }, {
      title: lang.relatives,
      key: 'relatives',
      href: PATH(language).extraPage('relatives')
    }],
    key: 'specials'
  }, {
    title: lang.blog,
    href: PATH(language).blog.index(),
    key: 'blog'
  }, {
    title: lang.aboutUs,
    href: PATH(language).aboutUs,
    key: 'aboutUs'
  }];

  // const links = [
  //     { title: lang.home, href: PATH(language).home(), searchParamsSensitive: false },
  //     { title: lang.services, href: PATH(language).services.index() },
  //     {
  //         title: lang.housing,
  //         id: ServiceTypeEnum.housing,
  //         children: [
  //             { title: lang.houses, href: PATH(language).housing.houses() },
  //             { title: lang.tenants, href: PATH(language).housing.roommates() },
  //         ],
  //     },
  //     { title: lang.hiring, id: ServiceTypeEnum.hiring },
  //     { title: lang.events, id: ServiceTypeEnum.events },
  //     {
  //         title: lang.dating,
  //         href: profile ? PATH(language).dating : undefined,
  //         id: profile ? undefined : ServiceTypeEnum.dating,
  //     },
  //     {
  //         title: lang.others,
  //         children: [
  //             { title: lang.blog, href: PATH(language).blog.index() },
  //             { title: lang.aboutUs, href: PATH(language).aboutUs },
  //         ],
  //     },
  // ];

  function menuItemGen(e: LinkType): ItemType {
    return {
      label: e.href ? <AppLinkComp className={`${isPage(e.href) ? 't-primary-i t-500' : ''}`} href={e.href} preventReload={isPage(e.href)} searchParamsSensitive={false}>
                    <span className="center-content-y">
                        <span className="flex-1">{e.title}</span>
                        {e.children?.length ? <TbChevronLeft size={18} /> : null}
                    </span>
                </AppLinkComp> : <span className={`center-content-y ${e.children?.find(i => i.href?.length && isPage(i.href)) ? 't-primary-i t-500' : ''}`}>
                    <span className="flex-1">{e.title}</span>
                    {e.children?.length ? <TbChevronLeft size={18} /> : null}
                </span>,
      key: e.key,
      children: e.children?.map(i => menuItemGen(i)) ?? [],
      expandIcon: <span></span>
    };
  }
  return <div className={`align-items-center d-pre-md-none ${styles['links']}`} data-sentry-component="HeaderLinksComp" data-sentry-source-file="links.index.tsx">
            {links2.map((link, index) => <Dropdown key={index} menu={{
      items: link.children?.map(i => menuItemGen(i)) ?? []
    }}>
                    {link.href?.length ? <AppLinkComp className={`${isPage(link.href) ? 't-primary-i t-500' : ''}`} href={link.href} preventReload={isPage(link.href)} searchParamsSensitive={false}>
                            <span className="center-content-y 't-primary-i">
                                <span className="flex-1">{link.title}</span>
                                {link.children?.length ? <TbChevronLeft size={18} /> : null}
                            </span>
                        </AppLinkComp> : <span className={`align-items-center gap-8 ${link.children?.find(i => i.href?.length && isPage(i.href)) ? 't-primary-i t-500' : ''}`}>
                            {link.title}
                            {link.children?.length ? <TbChevronDown /> : null}
                        </span>}
                </Dropdown>)}
        </div>;
}