import { __HiringLang } from './hiring/hiring.lang';

export const __BrandDetailLang = {
    filters: 'فیلتر ها',
    company_services: 'خدمات برند',
    newest: 'جدیدترین',
    oldest: 'قدیمی ترین',
    mostPriority: 'منتخب',
    most_visited: 'پربازدید ترین',
    most_popular: 'محبوب ترین',
    maximum_cashback: 'بیشترین بازگشت وجه',
    ordering: 'مرتب سازی',
    result: 'نتیجه',
    customers_comments: 'نظرات دریافت‌کنندگان خدمت',
    comments_service: 'نظر درباره خدمت',
    errorMessage: 'درخواست با خطا مواجه شد.',
    empty: 'خدمتی با فیلتر مورد نظر یافت نشد.',
    initializeError: 'انتخاب با خطا مواجه شد.',
    initializeSuccess: 'پیش سفارش شما با موفقیت ثبت شد',
    ranking: 'جایگاه در برترین‌ها',
    hiring: 'فرصت‌های شغلی',
    emptyRanking: 'چایگاه این برند در دسته بندی های خدمات یافت نشد.',
    rankingComments: 'نظرات رای دهندگان',
    noRankingComment: 'هیچ رای و نظری برای این برند در خدمات آن درج نشده است',
    commentsForBrand: 'نظر درباره برند',
    hiringSec: __HiringLang,
};
