export const headerLang = {
    tehranto: 'تـهرانتـو',
    otherLang: 'En',
    home: 'پلتفرم',
    verticals: 'نیازمندی‌های شهر',
    specials: 'خدمات ویژه',
    marketing: 'بازارسازی و بازاریابی',
    migration: 'مهاجرتی و ویزا',
    newComers: 'تازه‌واردین شهر',
    relatives: 'خدمات در زادگاه',
    services: 'تخفیف‌های شهر',
    dating: 'دوستیابی',
    events: 'دورهمی و همراهی',
    housing: 'خانه و هم‌خانه',
    hiring: 'کار و همکاری',
    aboutUs: 'تهرانتوشناسی',
    houses: 'خانه‌ها',
    tenants: 'همخانه‌ها',
    others: 'سایر',
    blog: 'تهرانتومگ',
    signup: ' درخواست شهروندی',
    login: ' ورود شهروند',
    profile: 'حساب کاربری',
};
