import { BPEnum, HouseType, LanguageEnum, PaginationType, TenantType } from '@/types';
import styles from './housing.module.scss';
import { SliderWrapperComp, AppLinkComp, HouseCardComp, RoommateCardComp } from 'components';
import { Button, Divider, Spin } from 'antd';
import { TbArrowLeft, TbArrowRight } from 'react-icons/tb';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/context';
import { API, PATH } from '@/data';
import { RestAPI, useBP } from '@/scripts';
function HousingTabContentComp({
  houses
}: {
  houses?: PaginationType<HouseType>;
}) {
  const bp = useBP();
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.landing2;
  const isFA = langText.lang === LanguageEnum.fa;
  const [houseList, setHouseList] = useState(houses);
  const [tenantList, setTenantList] = useState<PaginationType<TenantType>>();
  const [housingState, setHousingState] = useState<{
    type: 'house' | 'tenant';
    houseActiveSlide: number;
    tenantActiveSlide: number;
  }>({
    type: 'house',
    houseActiveSlide: 0,
    tenantActiveSlide: 0
  });
  const sliderLayout = [{
    max_width: BPEnum.SM,
    perView: 1.3,
    spacing: 12
  }, {
    max_width: BPEnum.MD,
    perView: 2.3,
    spacing: 12
  }, {
    max_width: BPEnum.LG,
    perView: 2.3
  }, {
    max_width: BPEnum.XL,
    perView: 2.9
  }, {
    max_width: BPEnum.XXL,
    perView: 3.9,
    origin: 0.04
  }];
  useEffect(() => {
    if (housingState.type === 'house' && !houseList) RestAPI.get<PaginationType<HouseType>>(API.housing.houseList, {
      page_size: 20
    }).then(res => setHouseList(res.data));
    if (housingState.type === 'tenant' && !tenantList) RestAPI.get<PaginationType<HouseType>>(API.housing.tenantList, {
      page_size: 20
    }).then(res => setTenantList(res.data));
  }, [housingState.type]);
  const sliderIndex = housingState.type === 'house' ? housingState.houseActiveSlide : housingState.tenantActiveSlide;
  const sliderLength = housingState.type === 'house' ? houseList?.count ?? 0 : tenantList?.count ?? 0;
  const housingKey = housingState.type === 'house' ? 'houseActiveSlide' : 'tenantActiveSlide';
  return <div className={`${styles['housing']}`} data-sentry-component="HousingTabContentComp" data-sentry-source-file="housing.index.tsx">
            <p>{lang.housingHint}</p>
            <Divider className="mb-0-i mt-4-i d-pre-md-none-i" data-sentry-element="Divider" data-sentry-source-file="housing.index.tsx" />
            <div className={styles['btn']}>
                <div onClick={() => setHousingState(e => ({
        ...e,
        type: 'house'
      }))} className={`${styles['list-items']} pv-1 ${housingState.type === 'house' ? styles['active'] : ''}`}>
                    <p className="t-400 t-dark3">{lang.houseSearch}</p>
                </div>
                <div onClick={() => setHousingState(e => ({
        ...e,
        type: 'tenant'
      }))} className={`${styles['list-items']} pv-1 ${housingState.type === 'tenant' ? styles['active'] : ''}`}>
                    <p className="t-400 t-dark3">{lang.tenantSearch}</p>
                </div>
            </div>
            {housingState.type === 'house' ? houseList ? <SliderWrapperComp items={houseList?.data} child={HouseCardComp} noLoop origin={0.03} loadingHeight={425} className={`pt-4 ${housingState.type === 'house' ? '' : 'd-none'}`} hideArrow={bp?.MD_LTE ? false : true} activeSlide={housingState.houseActiveSlide} onSlideChange={e => setHousingState(prev => ({
      ...prev,
      houseActiveSlide: e
    }))} layout={sliderLayout} /> : <div className={`${styles['houses-loading-box']} center-content w-100`}>
                        <Spin />
                    </div> : null}
            {housingState.type === 'tenant' ? tenantList ? <SliderWrapperComp items={tenantList.data} child={RoommateCardComp} noLoop origin={0.03} loadingHeight={275} className={`pt-4 ${housingState.type === 'tenant' ? '' : 'd-none'}`} hideArrow={bp?.MD_LTE ? false : true} activeSlide={housingState.tenantActiveSlide} onSlideChange={e => setHousingState(prev => ({
      ...prev,
      tenantActiveSlide: e
    }))} layout={sliderLayout} /> : <div className={`${styles['tenants-loading-box']} center-content w-100`}>
                        <Spin />
                    </div> : null}
            <div className={`${styles['arrow-btn']} center-content mt-4 gap-32`}>
                <span onClick={() => setHousingState(e => ({
        ...e,
        [housingKey]: isFA ? sliderIndex > 0 ? sliderIndex - 1 : 0 : sliderIndex < sliderLength - 1 ? sliderIndex + 1 : sliderLength - 1
      }))} className={`pointer center-content d-pre-md-none ${isFA && sliderIndex === 0 || !sliderLength ? styles['disabled'] : langText.lang === LanguageEnum.en && sliderIndex === sliderLength - 1 ? styles['disabled'] : ''}`}>
                    {isFA ? <TbArrowRight color="#ffffff" /> : <TbArrowLeft color="#ffffff" />}
                </span>
                <AppLinkComp href={housingState.type === 'house' ? PATH(langText.lang).housing.houses() : PATH(langText.lang).housing.roommates()} data-sentry-element="AppLinkComp" data-sentry-source-file="housing.index.tsx">
                    <Button className="ph-5-i" data-sentry-element="Button" data-sentry-source-file="housing.index.tsx">
                        {housingState.type === 'house' ? lang.moreHouseSearch : lang.moreTenantSearch}
                    </Button>
                </AppLinkComp>
                <span onClick={() => setHousingState(e => ({
        ...e,
        [housingKey]: isFA ? sliderIndex < sliderLength - 1 ? sliderIndex + 1 : sliderLength - 1 : sliderIndex > 0 ? sliderIndex - 1 : 0
      }))} className={`pointer center-content d-pre-md-none ${!sliderLength || isFA && sliderIndex === sliderLength - 1 ? styles['disabled'] : langText.lang === LanguageEnum.en && sliderIndex === 0 ? styles['disabled'] : ''}`}>
                    {isFA ? <TbArrowLeft color="#ffffff" /> : <TbArrowRight color="#ffffff" />}
                </span>
            </div>
        </div>;
}
export default HousingTabContentComp;