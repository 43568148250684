import {
    BrandRankingType,
    BrandType,
    CategoryType,
    GenderEnum,
    JobExperienceEnum,
    JobPositionType,
    JobPositionTypeEnum,
    JobSalaryTypeEnum,
    JobTimeEnum,
    PaginationType,
    ServiceType,
} from '@/types';

export type __BrandDetailPropsType = {
    data: BrandType;
    categories: CategoryType[];
    initialFilters: __BrandSearchParamsType;
    services?: PaginationType<ServiceType>;
    jobs?: PaginationType<JobPositionType>;
    rankings?: PaginationType<BrandRankingType<'detail'>>;
};

export enum __BrandSectionEnum {
    services = 'services',
    ranking = 'ranking',
    hiring = 'hiring',
}
export type __BrandFilterType = {
    ///// common
    section?: __BrandSectionEnum;
    page?: string;
    search?: string;
    order?: string;
    category?: string;
    ///// service
    starGTE?: number;
    starLTR?: number;
    //// hiring
    gender?: GenderEnum;
    salaryGTE?: number;
    salaryLTE?: number;
    agreementSalary?: boolean;
    time?: JobTimeEnum | JobTimeEnum[];
    workType?: JobPositionTypeEnum | JobPositionTypeEnum[];
    experience?: JobExperienceEnum | JobExperienceEnum[];
    salaryType?: JobSalaryTypeEnum | JobSalaryTypeEnum[];
};

export type __BrandSearchParamsType = Partial<Record<keyof __BrandFilterType, string[] | string>>;
