'use client';

import styles from './categoryList.module.scss';
import { BrandRankingType, CategoryType, PaginationType, ResponseType } from 'types';
import { useEffect, useState } from 'react';
import DragAbleComp from './dragAble/dragAble.index';
function CategoryListComp({
  categories,
  categorySelect,
  brands
}: {
  categories: CategoryType[];
  categorySelect: (e: Partial<CategoryType>) => void;
  brands?: ResponseType<PaginationType<BrandRankingType>>;
}) {
  const [activeIndex, setActiveIndex] = useState<Partial<CategoryType>>(categories[0]);
  const categoryHandler = (item: Partial<CategoryType>) => {
    setActiveIndex(item);
    categorySelect(item);
    const tabEl = document.getElementById(`rank-category-${item.id}`);
    const scrolled = tabEl?.parentElement?.scrollLeft ?? 0;
    const dim = tabEl?.getBoundingClientRect();
    const width = dim?.width ?? 0;
    const left = dim?.left ?? 0;
    const diff = window.innerWidth / 2 - left;
    tabEl?.parentElement?.scrollTo({
      left: scrolled - diff + width / 2,
      behavior: 'smooth'
    });
  };
  useEffect(() => {
    if (categories.length) categoryHandler(categories[0]);
  }, [categories]);
  if (!activeIndex && !categories.length) return [];
  return <div className={`${styles['category-list']} flex-column`} data-sentry-component="CategoryListComp" data-sentry-source-file="categoryList.index.tsx">
            <DragAbleComp parentClassName="main-content ph-4" className={`${styles['parent-category']} ${!brands ? styles['loading'] : ''} d-flex align-items-center pe-3 ps-2`} data-sentry-element="DragAbleComp" data-sentry-source-file="categoryList.index.tsx">
                {categories.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0)).map((item, index) => <div onClick={() => categoryHandler(item)} key={index} id={`rank-category-${item.id}`} className={`${styles['list-items']} pv-1 ${activeIndex.id === item.id ? styles['auto-active'] : ''}`} onAnimationEnd={() => {
        if (index < categories.length - 1) categoryHandler(categories[index + 1]);else categoryHandler(categories[0]);
      }}>
                            <p className="t-400 t-dark3">{item.title}</p>
                        </div>)}
            </DragAbleComp>
        </div>;
}
export default CategoryListComp;