export const __YTCampaignLang = {
    title: 'اسکن ۱۰ هزار دلاری!',
    desc1: 'اين فقط يك QRكد نيست! بلكه فرصتى بى نظير براى 100 نفراست تابا شركت در قرعه كشى، اعتبار 10,000 دلارى براى پوشش هزينه هاى مهاجرت، از اخذ ويزا واقامت تاشروع زندكى دركانادا، به دست آورند.',
    desc2: `اما خبر خوب اين است كه همه شركت كنندكان در اين کمپین برنده خواهند بود! ما به هر شركت كننده حداقل<span className="t-700 t-primary-i" style="color:#009de0;font-weight: 700;">10,000</span> دلار اعتبار اختصاص مى دهيم تا براى همين منظور بتوانند از خدمات برندهاى معتبرو مورد تاييد ما بهره مند شوند. `,
    let_go: 'فرصت را از دست ندهید، همین حالا شروع کنید!',
    language: 'سطح زبان',
    education: 'تحصیلات',
    financial: 'تمکن مالی',
    question1: 'سطح زبان انگلیسی یا فرانسوی شما',
    answer1q1: 'درحد متوسط | مثلا IELTS بين 5 تا 6',
    answer2q1: 'درحد خيلى كم | IELTS حدود 3 تا 5',
    answer3q1: 'درحد خوب | مثلا IELTS بين 6 تا 7',
    answer4q1: 'درحد عالى | مثلا IELTS بين 7 به بالا',
    prev: 'مرحله قبلی',
    next: 'مرحله بعد',
    question2: 'سطح تحصیلات شما',
    answer1q2: 'دیپلم',
    answer2q2: 'لیسانس',
    answer3q2: 'فوق لیسانس یا دکتری',
    question3: 'امکان ارائه تمکن مالی جهت ارائه به اداره مهاجرت کانادا؟',
    answer1q3: 'زیر 10,000$',
    answer2q3: 'بین 10,000$ الی 30,000$',
    answer3q3: 'بین 30,000$ الی 50,000$',
    answer4q3: 'بین 50,000$ الی 100,000$',
    answer5q3: 'بالای 100,000$',
    question5Title: 'دریافت اسکناس 10,000 دلاری',
    question4: 'کافیست شماره همراه خود را وارد کنید تا اطلاعات وارد شده شما ثبت گردد',
    placeholder: 'شماره همراه',
    submit: 'تایید',
    desc3: 'اطلاعات شما با موفقیت ثبت شد.',
    desc4: 'جهت شرکت در قرعه کشی کافیست اینستاگرام و یوتیوب ما رو Subscribe/Follow داشته باشید.',
    instagram: 'اینستاگرام',
    youtub: 'یوتیوب',
    desc5: ` آيا ميخواهيد شانس خود راتا 10 برابر كنيد؟ <br />
                به ازاى هر نفرى كه توسط اين لينك از طرف شما در اين كمپين شركت كند شانس شما بيشتر خواهد شد.`,
    desc6: 'تعداد شرکت کننده های دعوت شده شما:',
    chance: 'ده برابر کردن شانس!',
    error: 'لطفا یک گزینه را انتخاب کنید!',
    phone_error: 'لطفا شماره تلفن خود را وارد کنید',
    invalid_phone: 'لطفا شماره تلفن معتبر وارد کنید',
    code: 'کد تایید',
    error2: 'خطایی به وجود آمده است. لطفا بعدا تلاش کنید.',
    phoneIncorrect: 'لطفا یک شماره تماس صحیح وارد نمایید',
    phoneRequired: 'لطفا شماره تماس خود را وارد کنید',
    codeRequired: 'لطفا کد تایید ارسالی را وارد نمایید',
    codeSubmitError: 'اطلاعات وارد شده صحیح نیست. لطفا مجددا تلاش نمایید',
    resend_code_first: 'کدی دریافت نشد؟',
    resend_code_second: 'ارسال کد فعالسازی',
    resend_code_third: 'در',
    resend_code_end: 'ثانیه دیگر',
    change_phone: 'تغییر شماره همراه تو',
    search: 'جستجو...',
    copyAlert: 'لینک دعوت با موفقیت در کلیپ بردتان ذخیره شد!',
    result1: '.کمپین هنوز شروع نشده',
    result2: 'تاریخ کمپین به اتمام رسیده است.',
    result3: 'ظرفیت کمپین به اتمام رسیده است.',
};
