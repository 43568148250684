import { BPEnum, EventCategoryType, EventType, LanguageEnum, PaginationType } from '@/types';
import styles from './events.module.scss';
import { SliderWrapperComp, AppLinkComp, EventCardComp } from 'components';
import { Button, Divider, Spin, message } from 'antd';
import { TbArrowLeft, TbArrowRight } from 'react-icons/tb';
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext } from '@/context';
import { API, PATH } from '@/data';
import { RestAPI, useBP, useRealTimeState } from '@/scripts';
import DragAbleComp from '../../dragAble/dragAble.index';
const duration = 5000;
const steps = 25;
function EventsTabContentComp(props: {
  events?: PaginationType<EventType>;
  categories?: EventCategoryType[];
}) {
  const bp = useBP();
  const {
    langText
  } = useContext(GlobalContext);
  const lang = langText.landing2;
  const isFA = langText.lang === LanguageEnum.fa;
  const [activeSlide, setActiveSlide] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<Partial<EventCategoryType>>();
  const [messageApi, contextHolder] = message.useMessage();
  const [eventsList, setEventsList] = useState<PaginationType<EventType> | undefined>(props.events);
  const [serviceCategories, setServiceCategories] = useState(props.categories);
  const sliderLayout = [{
    max_width: BPEnum.SM,
    perView: 1.3,
    spacing: 12
  }, {
    max_width: BPEnum.MD,
    perView: 2.3,
    spacing: 12
  }, {
    max_width: BPEnum.LG,
    perView: 2.3
  }, {
    max_width: BPEnum.XL,
    perView: 2.9
  }, {
    max_width: BPEnum.XXL,
    perView: 3.9,
    origin: 0.04
  }];
  const [hintIndex, setHintIndex] = useState(0);
  const timeout = useRef<NodeJS.Timeout>();
  const progress = useRealTimeState(0);
  const categories: Partial<EventCategoryType>[] = [{
    title: langText.categoryList.all_category,
    slug: '',
    priority: Math.max(...(serviceCategories ?? []).map(i => i.priority ?? 0)) + 1
  }, ...(serviceCategories ?? [])];
  useEffect(() => {
    // start over slider
    setActiveSlide(0);
    if (!serviceCategories) RestAPI.get<PaginationType<EventCategoryType>>(API.event.categories, {
      lang: langText.lang,
      page_size: 100
    }).then(res => setServiceCategories(res.data.data));
    if (!eventsList) RestAPI.get<PaginationType<EventType>>(API.event.list, {
      page_size: 20,
      category__lang: langText.lang,
      category: selectedCategory?.id ?? '',
      is_active: true
    }).then(res => setEventsList(res.data));
  }, []);
  useEffect(() => {
    if (selectedCategory === undefined) setEventsList(props.events);else {
      setEventsList(undefined);
      RestAPI.get<PaginationType<EventType>>(API.event.list, {
        category__lang: langText.lang,
        category: selectedCategory?.id ?? '',
        is_active: true,
        page_size: 20
      }).then(res => setEventsList(res.data)).catch(() => messageApi.open({
        type: 'error',
        content: lang.errorMessage
      }));
    }
  }, [selectedCategory]);
  useEffect(() => {
    if (progress.get() >= 1) {
      progress.set(0);
      setHintIndex(hintIndex + 1 >= lang.offersHint.steps.length ? 0 : hintIndex + 1);
    }
  }, [progress.dep]);
  useEffect(() => {
    progress.set(0);
    setHintIndex(0);
    clearInterval(timeout.current);
    timeout.current = setInterval(() => {
      progress.set(progress.get() + 1 / steps);
    }, duration / steps);
  }, []);
  const categoryHandler = (item: Partial<EventCategoryType>) => {
    setSelectedCategory(item);
    const tabEl = document.getElementById(`landing-category-${item.id}`);
    const scrolled = tabEl?.parentElement?.scrollLeft ?? 0;
    const dim = tabEl?.getBoundingClientRect();
    const width = dim?.width ?? 0;
    const left = dim?.left ?? 0;
    const diff = window.innerWidth / 2 - left;
    tabEl?.parentElement?.scrollTo({
      left: scrolled - diff + width / 2,
      behavior: 'smooth'
    });
  };
  const innerData = categories.filter(i => selectedCategory?.parent ? i.parent?.slug === selectedCategory.parent.slug : selectedCategory?.slug ? i.parent?.slug === selectedCategory.slug : false);
  const sliderLength = eventsList?.data.length ?? 0;
  return <div className={`${styles['events']}`} data-sentry-component="EventsTabContentComp" data-sentry-source-file="events.index.tsx">
            {contextHolder}
            <p>{lang.eventsHint}</p>
            <Divider className="mb-4-i d-pre-md-none-i" data-sentry-element="Divider" data-sentry-source-file="events.index.tsx" />
            <div className={`${styles['slider']}`}>
                {serviceCategories ? <div className={`${styles['category-list']} flex-column`}>
                        <DragAbleComp parentClassName="main-content ph-4" className={`${styles['parent-category']} d-flex align-items-center pe-3 ps-2`}>
                            <span style={{
            flex: 1,
            display: 'block'
          }}></span>
                            {categories.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0)).filter(i => !i.slug || !i.parent).map((item, index) => <div onClick={() => categoryHandler(item)} key={index} id={`landing-category-${item.id}`} className={`${styles['list-items']} pv-1 ${selectedCategory?.parent ? selectedCategory.parent.slug === item.slug ? styles['active'] : '' : selectedCategory?.slug ? item.slug === selectedCategory.slug ? styles['active'] : '' : item.slug === '' ? styles['active'] : ''}`}>
                                        <p className="t-400 t-dark3">{item.title}</p>
                                    </div>)}
                            <span style={{
            flex: 1,
            display: 'block'
          }}></span>
                        </DragAbleComp>
                        {innerData.length ? <DragAbleComp parentClassName={`main-content ph-4 ${innerData.length ? 'mv-2' : ''}`} iconClassName="t-primary" className={`${styles['child-category']} d-flex align-items-center ph-3`}>
                                <span style={{
            flex: 1,
            display: 'block'
          }}></span>
                                {innerData.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0)).map((item, index) => <div onClick={() => setSelectedCategory(item)} key={index} className={`${styles['list-items']} p-1 ${selectedCategory?.slug === item.slug ? styles['active'] : ''}`}>
                                            <p className="t-400">{item.title}</p>
                                        </div>)}
                                <span style={{
            flex: 1,
            display: 'block'
          }}></span>
                            </DragAbleComp> : null}
                    </div> : null}
                {eventsList ? <SliderWrapperComp items={eventsList.data} child={EventCardComp} noLoop origin={0.03} loadingHeight={315} className={`pt-4 ${styles['slider-content']}`} hideArrow={bp?.MD_LTE ? false : true} activeSlide={activeSlide} onSlideChange={e => setActiveSlide(e)} layout={sliderLayout} /> : <div className={`${styles['loading-box']} center-content w-100`}>
                        <Spin />
                    </div>}
                <div className={`${styles['arrow-btn']} center-content mt-4 gap-32`}>
                    <span onClick={() => setActiveSlide(isFA ? prev => prev > 0 ? prev - 1 : 0 : prev => prev < sliderLength - 1 ? prev + 1 : sliderLength - 1)} className={`pointer center-content d-pre-md-none ${isFA && activeSlide === 0 || !sliderLength ? styles['disabled'] : langText.lang === LanguageEnum.en && activeSlide === sliderLength - 1 ? styles['disabled'] : ''}`}>
                        {isFA ? <TbArrowRight color="#ffffff" /> : <TbArrowLeft color="#ffffff" />}
                    </span>
                    <AppLinkComp href={eventsList && eventsList.count === 0 ? PATH(langText.lang).events.index() : PATH(langText.lang).events.index({
          category: selectedCategory?.slug ?? ''
        })} data-sentry-element="AppLinkComp" data-sentry-source-file="events.index.tsx">
                        <Button loading={!eventsList} className="ph-5-i" data-sentry-element="Button" data-sentry-source-file="events.index.tsx">
                            {eventsList && eventsList.count === 0 ? bp?.MD_LTE ? lang.seeAllEvents : lang.seeAllEvents : bp?.MD_LTE ? lang.seeAll : lang.see + ' ' + (eventsList?.count ?? '') + ' ' + lang.event}
                        </Button>
                    </AppLinkComp>
                    <span onClick={() => setActiveSlide(isFA ? prev => prev < sliderLength - 1 ? prev + 1 : sliderLength - 1 : prev => prev > 0 ? prev - 1 : 0)} className={`pointer center-content d-pre-md-none ${!sliderLength || isFA && activeSlide === sliderLength - 1 ? styles['disabled'] : langText.lang === LanguageEnum.en && activeSlide === 0 ? styles['disabled'] : ''}`}>
                        {isFA ? <TbArrowLeft color="#ffffff" /> : <TbArrowRight color="#ffffff" />}
                    </span>
                </div>
            </div>
        </div>;
}
export default EventsTabContentComp;