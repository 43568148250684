'use client';

import { BrandRankingType } from '@/types';
import styles from './bestCard.module.scss';
import { Button, Collapse, CollapseProps, Drawer, Input, message, Modal } from 'antd';
import Image from 'next/image';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { GoArrowDown, GoArrowUp } from 'react-icons/go';
import { COLORS } from '@/utilities';
import { GiTrophyCup } from 'react-icons/gi';
import { useContext, useState } from 'react';
import { GlobalContext } from '@/context';
import { RiDiscordFill, RiFacebookFill, RiInstagramFill, RiLinkedinFill, RiRedditFill, RiTelegramFill, RiTiktokFill, RiTwitterFill, RiWhatsappFill, RiYoutubeFill } from 'react-icons/ri';
import { TbArrowNarrowDown, TbArrowNarrowUp, TbChevronLeft, TbWorld } from 'react-icons/tb';
import { IoIosArrowBack } from 'react-icons/io';
import { RestAPI, useAsyncClick, useBP } from '@/scripts';
import { API, PATH } from '@/data';
import { AuthModalComp } from '@/components';
import { __BrandSectionEnum } from '@/tehranto/brandDetail/brandDetail.type';
function BestCardComp({
  data,
  pauseAnimation,
  resumeAnimation
}: {
  data: BrandRankingType;
  pauseAnimation: () => void;
  resumeAnimation: () => void;
}) {
  const myReview = data.my_reviews?.at(0);
  const [vote, setVote] = useState<'up' | 'down' | undefined>(myReview ? myReview.like ? 'up' : 'down' : undefined);
  const [likes, setLikes] = useState(data.likes_count);
  const [disLikes, setDisLikes] = useState(data.dislike_count);
  const {
    langText,
    profile
  } = useContext(GlobalContext);
  const lang = langText.bests;
  const image = data.brand.main_image;
  const [selectedVote, setSelected] = useState<'up' | 'down'>();
  const [review, setReview] = useState(myReview?.comment ?? '');
  const [login, setLogin] = useState<'up' | 'down'>();
  const [messageApi, contextHolder] = message.useMessage();
  const bp = useBP();
  const isMobile = bp?.MD_LTE;
  const onSubmit = useAsyncClick(() => {
    if (vote === 'up') {
      setLikes(likes - 1);
    } else if (vote === 'down') {
      setDisLikes(disLikes - 1);
    }
    setVote(undefined);
    return RestAPI.post(API.ranking.add, {
      brand_category: data.id,
      brand: data.brand.id,
      like: selectedVote === 'up',
      comment: review.length ? review : undefined
    }).then(() => {
      if (selectedVote === 'up') {
        setLikes(likes + 1);
        setVote('up');
      } else if (selectedVote === 'down') {
        setDisLikes(disLikes + 1);
        setVote('down');
      }
      messageApi.success(lang.voteSuccess);
      setSelected(undefined);
    }).catch(() => {
      messageApi.error(langText.general.error);
    });
  });
  function voteHandler(e: any, v: 'up' | 'down') {
    e?.preventDefault();
    e?.stopPropagation();
    pauseAnimation();
    if (!profile) {
      setLogin(v);
    } else setSelected(v);
  }
  const best = data.rank && data.rank < 6;
  const socials = [{
    label: 'RedditF',
    link: data.brand.reddit,
    icon: RiRedditFill
  }, {
    label: 'Discord',
    link: data.brand.discord,
    icon: RiDiscordFill
  }, {
    label: 'TikTok',
    link: data.brand.tiktok,
    icon: RiTiktokFill
  }, {
    label: 'Linkedin',
    link: data.brand.linkedin,
    icon: RiLinkedinFill
  }, {
    label: 'Facebook',
    link: data.brand.facebook,
    icon: RiFacebookFill
  }, {
    label: 'Youtube',
    link: data.brand.youtube,
    icon: RiYoutubeFill
  }, {
    label: 'Twitter',
    link: data.brand.twitter,
    icon: RiTwitterFill
  }, {
    label: 'Instagram',
    link: data.brand.instagram,
    icon: RiInstagramFill
  }, {
    label: 'Whatsapp',
    link: data.brand.whatsapp,
    icon: RiWhatsappFill
  }, {
    label: 'Telegram',
    link: data.brand.telegram,
    icon: RiTelegramFill
  }, {
    label: 'TikTok',
    link: data.brand.website,
    icon: TbWorld
  }];
  const CollapseHeaderContent = () => {
    return <div className={styles['collapse-header-content']} data-sentry-component="CollapseHeaderContent" data-sentry-source-file="bestCard.index.tsx">
                <div className="center-content-y flex-between">
                    <div className={`${styles['right-section']} center-content-y`}>
                        {data.is_ad ? <div className={`${styles['ad']} center-content d-pre-md-none`}>
                                <p>Ad</p>
                            </div> : best ? <div className={`${styles['best']} center-content d-pre-md-none`}>
                                <GiTrophyCup size={18} color="#fff" />
                            </div> : <div className={`${styles['index']} center-content d-pre-md-none`}>
                                <p>{data.rank}</p>
                            </div>}
                        <div className={`${styles['image']}`}>
                            {image ? <Image src={image.file} width={50} height={50} alt={data.brand.title} /> : null}
                        </div>
                        <div className={`${styles['title']} center-content mh-1`}>
                            <p className="t-p1 t-500 t-dark1 center-content">
                                {data.brand.title}{' '}
                                <MdOutlineKeyboardArrowDown size={16} className="mh-1-i d-pre-md-none" data-sentry-element="MdOutlineKeyboardArrowDown" data-sentry-source-file="bestCard.index.tsx" />
                            </p>
                        </div>
                    </div>
                    <div className={`${styles['left-section']} center-content d-pre-md-none`}>
                        <div onClick={e => voteHandler(e, 'down')} className={`${styles['down']} ${vote === 'down' ? styles['down-active'] : ''} center-content`}>
                            <GoArrowDown size={20} color={vote === 'down' ? '#fff' : COLORS.red} data-sentry-element="GoArrowDown" data-sentry-source-file="bestCard.index.tsx" />
                            <p className="t-red t-500">{disLikes}</p>{' '}
                        </div>
                        <div onClick={e => voteHandler(e, 'up')} className={`${styles['up']} ${vote === 'up' ? styles['up-active'] : ''} center-content`}>
                            <p className="t-primary t-500">{likes}</p>{' '}
                            <GoArrowUp size={20} color={vote === 'up' ? '#fff' : COLORS.primary} data-sentry-element="GoArrowUp" data-sentry-source-file="bestCard.index.tsx" />
                        </div>
                    </div>
                </div>
                <div className={`${styles['mobile-actions']} d-md-none mt-2  center-content-y`}>
                    <div className={styles['actions']}>
                        <button onClick={e => voteHandler(e, 'down')} className={vote === 'down' ? styles['active'] : ''}>
                            <TbArrowNarrowDown size={20} data-sentry-element="TbArrowNarrowDown" data-sentry-source-file="bestCard.index.tsx" />
                            <span>{disLikes}</span>
                        </button>
                        <p>{data.is_ad ? 'Ad' : lang.rank(data.rank ?? 0)}</p>
                        <button onClick={e => voteHandler(e, 'up')} className={vote === 'up' ? styles['active'] : ''}>
                            <span>{likes}</span>
                            <TbArrowNarrowUp size={20} data-sentry-element="TbArrowNarrowUp" data-sentry-source-file="bestCard.index.tsx" />
                        </button>
                    </div>
                    <button>
                        <span>{lang.aboutBrand}</span>
                        <TbChevronLeft data-sentry-element="TbChevronLeft" data-sentry-source-file="bestCard.index.tsx" />
                    </button>
                </div>
            </div>;
  };
  const finalSocials = socials.filter(i => i.link?.length);
  const CollapseBodyContent = () => {
    return <div className={`${styles['collapse-body-layout']}`} data-sentry-component="CollapseBodyContent" data-sentry-source-file="bestCard.index.tsx">
                <p className="t-500 t-dark1 t-p1 mb-1">{lang.descTitle}</p>
                <p className={`${styles['desc-brand']} t-dark2 t-400 t-p1 pb-2`}>{data.brand.description}</p>
                <div className={styles['footer-tools']}>
                    <div className="d-flex gap-16">
                        {finalSocials.length ? <>
                                <p className="t-500 t-dark1 t-p1">{lang.quickAccess}</p>
                                <span className="gap-16 flex-wrap">
                                    {finalSocials.map((item, index) => <a key={index} aria-label={item.label} target="_blank" href={item.link ?? ''}>
                                            <item.icon size={24} color={COLORS.dark2} />
                                        </a>)}
                                </span>
                            </> : null}
                    </div>
                    <a className="t-500 t-p1 t-primary center-content" href={PATH(langText.lang).brand(data.brand.slug, {
          section: __BrandSectionEnum.ranking
        })} target="_blank">
                        {lang.seeMore} <IoIosArrowBack size={20} color={COLORS.primary} className="mh-1-i" data-sentry-element="IoIosArrowBack" data-sentry-source-file="bestCard.index.tsx" />
                    </a>
                </div>
            </div>;
  };
  const items: CollapseProps['items'] = [{
    key: data.id,
    label: <CollapseHeaderContent />,
    children: <CollapseBodyContent />
  }];
  const Content = <>
            <p className="mb-2">{lang.reviewDesc}</p>
            <Input.TextArea placeholder={lang.review} value={review} onChange={e => setReview(e.target.value)} />
            <div className={styles['review-action']}>
                <Button type="text" onClick={() => {
        setSelected(undefined);
        resumeAnimation();
      }}>
                    {langText.general.cancel}
                </Button>
                <Button loading={onSubmit.loading} onClick={onSubmit.onClick} className={`w-100`}>
                    {langText.general.confirm}
                </Button>
            </div>
        </>;
  return <>
            {contextHolder}
            <AuthModalComp open={login !== undefined} onClose={() => {
      setLogin(undefined);
      resumeAnimation();
    }} onSuccess={() => {
      setSelected(login);
      setLogin(undefined);
    }} data-sentry-element="AuthModalComp" data-sentry-source-file="bestCard.index.tsx" />
            {isMobile ? <Drawer open={selectedVote !== undefined} placement="bottom" height={'auto'} className="pt-4 pb-2 ph-2" closeIcon={null}>
                    {Content}
                </Drawer> : <Modal open={selectedVote !== undefined} closeIcon={null} footer={null}>
                    {Content}
                </Modal>}
            <Collapse className={`best-brands-collapse ${best ? 'best-brands' : ''} ${best ? styles['best-brand-radius'] : ''} mb-4`} items={items} expandIcon={() => null} data-sentry-element="Collapse" data-sentry-source-file="bestCard.index.tsx" />
        </>;
}
export default BestCardComp;