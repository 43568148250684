const phone = [
    {
        desc: 'Canada',
        emoji: '🇨🇦',
        short: 'CA',
        value: '+1',
        label: '+1',
    },
    {
        desc: 'Iran',
        emoji: '🇮🇷',
        short: 'IR',
        value: '+98',
        label: '+98',
    },
    {
        desc: 'divider',
        emoji: 'divider',
        short: 'divider',
        value: 'divider',
        label: 'divider',
        className: 'align-items-center m-t--2 z-index-0',
        disabled: true,
    },
    {
        desc: 'Afghanistan',
        emoji: '🇦🇫',
        short: 'AF',
        value: '+93',
        label: '+93',
    },
    {
        desc: 'Åland Islands',
        emoji: '🇦🇽',
        short: 'AX',
        value: '+358',
        label: '+358',
    },
    {
        desc: 'Albania',
        emoji: '🇦🇱',
        short: 'AL',
        value: '+355',
        label: '+355',
    },
    {
        desc: 'Algeria',
        emoji: '🇩🇿',
        short: 'DZ',
        value: '+213',
        label: '+213',
    },
    {
        desc: 'American Samoa',
        emoji: '🇦🇸',
        short: 'AS',
        value: '+1684',
        label: '+1684',
    },
    {
        desc: 'Andorra',
        emoji: '🇦🇩',
        short: 'AD',
        value: '+376',
        label: '+376',
    },
    {
        desc: 'Angola',
        emoji: '🇦🇴',
        short: 'AO',
        value: '+244',
        label: '+244',
    },
    {
        desc: 'Anguilla',
        emoji: '🇦🇮',
        short: 'AI',
        value: '+1264',
        label: '+1264',
    },
    {
        desc: 'Antarctica',
        emoji: '🇦🇶',
        short: 'AQ',
        value: '+672',
        label: '+672',
    },
    {
        desc: 'Antigua and Barbuda',
        emoji: '🇦🇬',
        short: 'AG',
        value: '+1268',
        label: '+1268',
    },
    {
        desc: 'Argentina',
        emoji: '🇦🇷',
        short: 'AR',
        value: '+54',
        label: '+54',
    },
    {
        desc: 'Armenia',
        emoji: '🇦🇲',
        short: 'AM',
        value: '+374',
        label: '+374',
    },
    {
        desc: 'Aruba',
        emoji: '🇦🇼',
        short: 'AW',
        value: '+297',
        label: '+297',
    },
    {
        desc: 'Australia',
        emoji: '🇦🇺',
        short: 'AU',
        value: '+61',
        label: '+61',
    },
    {
        desc: 'Austria',
        emoji: '🇦🇹',
        short: 'AT',
        value: '+43',
        label: '+43',
    },
    {
        desc: 'Azerbaijan',
        emoji: '🇦🇿',
        short: 'AZ',
        value: '+994',
        label: '+994',
    },
    {
        desc: 'Bahamas',
        emoji: '🇧🇸',
        short: 'BS',
        value: '+1242',
        label: '+1242',
    },
    {
        desc: 'Bahrain',
        emoji: '🇧🇭',
        short: 'BH',
        value: '+973',
        label: '+973',
    },
    {
        desc: 'Bangladesh',
        emoji: '🇧🇩',
        short: 'BD',
        value: '+880',
        label: '+880',
    },
    {
        desc: 'Barbados',
        emoji: '🇧🇧',
        short: 'BB',
        value: '+1246',
        label: '+1246',
    },
    {
        desc: 'Belarus',
        emoji: '🇧🇾',
        short: 'BY',
        value: '+375',
        label: '+375',
    },
    {
        desc: 'Belgium',
        emoji: '🇧🇪',
        short: 'BE',
        value: '+32',
        label: '+32',
    },
    {
        desc: 'Belize',
        emoji: '🇧🇿',
        short: 'BZ',
        value: '+501',
        label: '+501',
    },
    {
        desc: 'Benin',
        emoji: '🇧🇯',
        short: 'BJ',
        value: '+229',
        label: '+229',
    },
    {
        desc: 'Bermuda',
        emoji: '🇧🇲',
        short: 'BM',
        value: '+1441',
        label: '+1441',
    },
    {
        desc: 'Bhutan',
        emoji: '🇧🇹',
        short: 'BT',
        value: '+975',
        label: '+975',
    },
    {
        desc: 'Bolivia, Plurinational State of bolivia',
        emoji: '🇧🇴',
        short: 'BO',
        value: '+591',
        label: '+591',
    },
    {
        desc: 'Bosnia and Herzegovina',
        emoji: '🇧🇦',
        short: 'BA',
        value: '+387',
        label: '+387',
    },
    {
        desc: 'Botswana',
        emoji: '🇧🇼',
        short: 'BW',
        value: '+267',
        label: '+267',
    },
    {
        desc: 'Bouvet Island',
        emoji: '🇧🇻',
        short: 'BV',
        value: '+47',
        label: '+47',
    },
    {
        desc: 'Brazil',
        emoji: '🇧🇷',
        short: 'BR',
        value: '+55',
        label: '+55',
    },
    {
        desc: 'British Indian Ocean Territory',
        emoji: '🇮🇴',
        short: 'IO',
        value: '+246',
        label: '+246',
    },
    {
        desc: 'Brunei Darussalam',
        emoji: '🇧🇳',
        short: 'BN',
        value: '+673',
        label: '+673',
    },
    {
        desc: 'Bulgaria',
        emoji: '🇧🇬',
        short: 'BG',
        value: '+359',
        label: '+359',
    },
    {
        desc: 'Burkina Faso',
        emoji: '🇧🇫',
        short: 'BF',
        value: '+226',
        label: '+226',
    },
    {
        desc: 'Burundi',
        emoji: '🇧🇮',
        short: 'BI',
        value: '+257',
        label: '+257',
    },
    {
        desc: 'Cambodia',
        emoji: '🇰🇭',
        short: 'KH',
        value: '+855',
        label: '+855',
    },
    {
        desc: 'Cameroon',
        emoji: '🇨🇲',
        short: 'CM',
        value: '+237',
        label: '+237',
    },
    {
        desc: 'Cape Verde',
        emoji: '🇨🇻',
        short: 'CV',
        value: '+238',
        label: '+238',
    },
    {
        desc: 'Cayman Islands',
        emoji: '🇰🇾',
        short: 'KY',
        value: '+345',
        label: '+345',
    },
    {
        desc: 'Central African Republic',
        emoji: '🇨🇫',
        short: 'CF',
        value: '+236',
        label: '+236',
    },
    {
        desc: 'Chad',
        emoji: '🇹🇩',
        short: 'TD',
        value: '+235',
        label: '+235',
    },
    {
        desc: 'Chile',
        emoji: '🇨🇱',
        short: 'CL',
        value: '+56',
        label: '+56',
    },
    {
        desc: 'China',
        emoji: '🇨🇳',
        short: 'CN',
        value: '+86',
        label: '+86',
    },
    {
        desc: 'Christmas Island',
        emoji: '🇨🇽',
        short: 'CX',
        value: '+61',
        label: '+61',
    },
    {
        desc: 'Cocos (Keeling) Islands',
        emoji: '🇨🇨',
        short: 'CC',
        value: '+61',
        label: '+61',
    },
    {
        desc: 'Colombia',
        emoji: '🇨🇴',
        short: 'CO',
        value: '+57',
        label: '+57',
    },
    {
        desc: 'Comoros',
        emoji: '🇰🇲',
        short: 'KM',
        value: '+269',
        label: '+269',
    },
    {
        desc: 'Congo',
        emoji: '🇨🇬',
        short: 'CG',
        value: '+242',
        label: '+242',
    },
    {
        desc: 'Congo, The Democratic Republic of the Congo',
        emoji: '🇨🇩',
        short: 'CD',
        value: '+243',
        label: '+243',
    },
    {
        desc: 'Cook Islands',
        emoji: '🇨🇰',
        short: 'CK',
        value: '+682',
        label: '+682',
    },
    {
        desc: 'Costa Rica',
        emoji: '🇨🇷',
        short: 'CR',
        value: '+506',
        label: '+506',
    },
    {
        desc: "Cote d'Ivoire",
        emoji: '🇨🇮',
        short: 'CI',
        value: '+225',
        label: '+225',
    },
    {
        desc: 'Croatia',
        emoji: '🇭🇷',
        short: 'HR',
        value: '+385',
        label: '+385',
    },
    {
        desc: 'Cuba',
        emoji: '🇨🇺',
        short: 'CU',
        value: '+53',
        label: '+53',
    },
    {
        desc: 'Cyprus',
        emoji: '🇨🇾',
        short: 'CY',
        value: '+357',
        label: '+357',
    },
    {
        desc: 'Czech Republic',
        emoji: '🇨🇿',
        short: 'CZ',
        value: '+420',
        label: '+420',
    },
    {
        desc: 'Denmark',
        emoji: '🇩🇰',
        short: 'DK',
        value: '+45',
        label: '+45',
    },
    {
        desc: 'Djibouti',
        emoji: '🇩🇯',
        short: 'DJ',
        value: '+253',
        label: '+253',
    },
    {
        desc: 'Dominica',
        emoji: '🇩🇲',
        short: 'DM',
        value: '+1767',
        label: '+1767',
    },
    {
        desc: 'Dominican Republic',
        emoji: '🇩🇴',
        short: 'DO',
        value: '+1849',
        label: '+1849',
    },
    {
        desc: 'Ecuador',
        emoji: '🇪🇨',
        short: 'EC',
        value: '+593',
        label: '+593',
    },
    {
        desc: 'Egypt',
        emoji: '🇪🇬',
        short: 'EG',
        value: '+20',
        label: '+20',
    },
    {
        desc: 'El Salvador',
        emoji: '🇸🇻',
        short: 'SV',
        value: '+503',
        label: '+503',
    },
    {
        desc: 'Equatorial Guinea',
        emoji: '🇬🇶',
        short: 'GQ',
        value: '+240',
        label: '+240',
    },
    {
        desc: 'Eritrea',
        emoji: '🇪🇷',
        short: 'ER',
        value: '+291',
        label: '+291',
    },
    {
        desc: 'Estonia',
        emoji: '🇪🇪',
        short: 'EE',
        value: '+372',
        label: '+372',
    },
    {
        desc: 'Ethiopia',
        emoji: '🇪🇹',
        short: 'ET',
        value: '+251',
        label: '+251',
    },
    {
        desc: 'Falkland Islands (Malvinas)',
        emoji: '🇫🇰',
        short: 'FK',
        value: '+500',
        label: '+500',
    },
    {
        desc: 'Faroe Islands',
        emoji: '🇫🇴',
        short: 'FO',
        value: '+298',
        label: '+298',
    },
    {
        desc: 'Fiji',
        emoji: '🇫🇯',
        short: 'FJ',
        value: '+679',
        label: '+679',
    },
    {
        desc: 'Finland',
        emoji: '🇫🇮',
        short: 'FI',
        value: '+358',
        label: '+358',
    },
    {
        desc: 'France',
        emoji: '🇫🇷',
        short: 'FR',
        value: '+33',
        label: '+33',
    },
    {
        desc: 'French Guiana',
        emoji: '🇬🇫',
        short: 'GF',
        value: '+594',
        label: '+594',
    },
    {
        desc: 'French Polynesia',
        emoji: '🇵🇫',
        short: 'PF',
        value: '+689',
        label: '+689',
    },
    {
        desc: 'French Southern Territories',
        emoji: '🇹🇫',
        short: 'TF',
        value: '+262',
        label: '+262',
    },
    {
        desc: 'Gabon',
        emoji: '🇬🇦',
        short: 'GA',
        value: '+241',
        label: '+241',
    },
    {
        desc: 'Gambia',
        emoji: '🇬🇲',
        short: 'GM',
        value: '+220',
        label: '+220',
    },
    {
        desc: 'Georgia',
        emoji: '🇬🇪',
        short: 'GE',
        value: '+995',
        label: '+995',
    },
    {
        desc: 'Germany',
        emoji: '🇩🇪',
        short: 'DE',
        value: '+49',
        label: '+49',
    },
    {
        desc: 'Ghana',
        emoji: '🇬🇭',
        short: 'GH',
        value: '+233',
        label: '+233',
    },
    {
        desc: 'Gibraltar',
        emoji: '🇬🇮',
        short: 'GI',
        value: '+350',
        label: '+350',
    },
    {
        desc: 'Greece',
        emoji: '🇬🇷',
        short: 'GR',
        value: '+30',
        label: '+30',
    },
    {
        desc: 'Greenland',
        emoji: '🇬🇱',
        short: 'GL',
        value: '+299',
        label: '+299',
    },
    {
        desc: 'Grenada',
        emoji: '🇬🇩',
        short: 'GD',
        value: '+1473',
        label: '+1473',
    },
    {
        desc: 'Guadeloupe',
        emoji: '🇬🇵',
        short: 'GP',
        value: '+590',
        label: '+590',
    },
    {
        desc: 'Guam',
        emoji: '🇬🇺',
        short: 'GU',
        value: '+1671',
        label: '+1671',
    },
    {
        desc: 'Guatemala',
        emoji: '🇬🇹',
        short: 'GT',
        value: '+502',
        label: '+502',
    },
    {
        desc: 'Guernsey',
        emoji: '🇬🇬',
        short: 'GG',
        value: '+44',
        label: '+44',
    },
    {
        desc: 'Guinea',
        emoji: '🇬🇳',
        short: 'GN',
        value: '+224',
        label: '+224',
    },
    {
        desc: 'Guinea-Bissau',
        emoji: '🇬🇼',
        short: 'GW',
        value: '+245',
        label: '+245',
    },
    {
        desc: 'Guyana',
        emoji: '🇬🇾',
        short: 'GY',
        value: '+592',
        label: '+592',
    },
    {
        desc: 'Haiti',
        emoji: '🇭🇹',
        short: 'HT',
        value: '+509',
        label: '+509',
    },
    {
        desc: 'Heard Island and Mcdonald Islands',
        emoji: '🇭🇲',
        short: 'HM',
        value: '+672',
        label: '+672',
    },
    {
        desc: 'Holy See (Vatican City State)',
        emoji: '🇻🇦',
        short: 'VA',
        value: '+379',
        label: '+379',
    },
    {
        desc: 'Honduras',
        emoji: '🇭🇳',
        short: 'HN',
        value: '+504',
        label: '+504',
    },
    {
        desc: 'Hong Kong',
        emoji: '🇭🇰',
        short: 'HK',
        value: '+852',
        label: '+852',
    },
    {
        desc: 'Hungary',
        emoji: '🇭🇺',
        short: 'HU',
        value: '+36',
        label: '+36',
    },
    {
        desc: 'Iceland',
        emoji: '🇮🇸',
        short: 'IS',
        value: '+354',
        label: '+354',
    },
    {
        desc: 'India',
        emoji: '🇮🇳',
        short: 'IN',
        value: '+91',
        label: '+91',
    },
    {
        desc: 'Indonesia',
        emoji: '🇮🇩',
        short: 'ID',
        value: '+62',
        label: '+62',
    },
    {
        desc: 'Iraq',
        emoji: '🇮🇶',
        short: 'IQ',
        value: '+964',
        label: '+964',
    },
    {
        desc: 'Ireland',
        emoji: '🇮🇪',
        short: 'IE',
        value: '+353',
        label: '+353',
    },
    {
        desc: 'Isle of Man',
        emoji: '🇮🇲',
        short: 'IM',
        value: '+44',
        label: '+44',
    },
    {
        desc: 'Israel',
        emoji: '🇮🇱',
        short: 'IL',
        value: '+972',
        label: '+972',
    },
    {
        desc: 'Italy',
        emoji: '🇮🇹',
        short: 'IT',
        value: '+39',
        label: '+39',
    },
    {
        desc: 'Jamaica',
        emoji: '🇯🇲',
        short: 'JM',
        value: '+1876',
        label: '+1876',
    },
    {
        desc: 'Japan',
        emoji: '🇯🇵',
        short: 'JP',
        value: '+81',
        label: '+81',
    },
    {
        desc: 'Jersey',
        emoji: '🇯🇪',
        short: 'JE',
        value: '+44',
        label: '+44',
    },
    {
        desc: 'Jordan',
        emoji: '🇯🇴',
        short: 'JO',
        value: '+962',
        label: '+962',
    },
    {
        desc: 'Kazakhstan',
        emoji: '🇰🇿',
        short: 'KZ',
        value: '+7',
        label: '+7',
    },
    {
        desc: 'Kenya',
        emoji: '🇰🇪',
        short: 'KE',
        value: '+254',
        label: '+254',
    },
    {
        desc: 'Kiribati',
        emoji: '🇰🇮',
        short: 'KI',
        value: '+686',
        label: '+686',
    },
    {
        desc: "Korea, Democratic People's Republic of Korea",
        emoji: '🇰🇵',
        short: 'KP',
        value: '+850',
        label: '+850',
    },
    {
        desc: 'Korea, Republic of South Korea',
        emoji: '🇰🇷',
        short: 'KR',
        value: '+82',
        label: '+82',
    },
    {
        desc: 'Kosovo',
        emoji: '🇽🇰',
        short: 'XK',
        value: '+383',
        label: '+383',
    },
    {
        desc: 'Kuwait',
        emoji: '🇰🇼',
        short: 'KW',
        value: '+965',
        label: '+965',
    },
    {
        desc: 'Kyrgyzstan',
        emoji: '🇰🇬',
        short: 'KG',
        value: '+996',
        label: '+996',
    },
    {
        desc: 'Laos',
        emoji: '🇱🇦',
        short: 'LA',
        value: '+856',
        label: '+856',
    },
    {
        desc: 'Latvia',
        emoji: '🇱🇻',
        short: 'LV',
        value: '+371',
        label: '+371',
    },
    {
        desc: 'Lebanon',
        emoji: '🇱🇧',
        short: 'LB',
        value: '+961',
        label: '+961',
    },
    {
        desc: 'Lesotho',
        emoji: '🇱🇸',
        short: 'LS',
        value: '+266',
        label: '+266',
    },
    {
        desc: 'Liberia',
        emoji: '🇱🇷',
        short: 'LR',
        value: '+231',
        label: '+231',
    },
    {
        desc: 'Libyan Arab Jamahiriya',
        emoji: '🇱🇾',
        short: 'LY',
        value: '+218',
        label: '+218',
    },
    {
        desc: 'Liechtenstein',
        emoji: '🇱🇮',
        short: 'LI',
        value: '+423',
        label: '+423',
    },
    {
        desc: 'Lithuania',
        emoji: '🇱🇹',
        short: 'LT',
        value: '+370',
        label: '+370',
    },
    {
        desc: 'Luxembourg',
        emoji: '🇱🇺',
        short: 'LU',
        value: '+352',
        label: '+352',
    },
    {
        desc: 'Macao',
        emoji: '🇲🇴',
        short: 'MO',
        value: '+853',
        label: '+853',
    },
    {
        desc: 'Macedonia',
        emoji: '🇲🇰',
        short: 'MK',
        value: '+389',
        label: '+389',
    },
    {
        desc: 'Madagascar',
        emoji: '🇲🇬',
        short: 'MG',
        value: '+261',
        label: '+261',
    },
    {
        desc: 'Malawi',
        emoji: '🇲🇼',
        short: 'MW',
        value: '+265',
        label: '+265',
    },
    {
        desc: 'Malaysia',
        emoji: '🇲🇾',
        short: 'MY',
        value: '+60',
        label: '+60',
    },
    {
        desc: 'Maldives',
        emoji: '🇲🇻',
        short: 'MV',
        value: '+960',
        label: '+960',
    },
    {
        desc: 'Mali',
        emoji: '🇲🇱',
        short: 'ML',
        value: '+223',
        label: '+223',
    },
    {
        desc: 'Malta',
        emoji: '🇲🇹',
        short: 'MT',
        value: '+356',
        label: '+356',
    },
    {
        desc: 'Marshall Islands',
        emoji: '🇲🇭',
        short: 'MH',
        value: '+692',
        label: '+692',
    },
    {
        desc: 'Martinique',
        emoji: '🇲🇶',
        short: 'MQ',
        value: '+596',
        label: '+596',
    },
    {
        desc: 'Mauritania',
        emoji: '🇲🇷',
        short: 'MR',
        value: '+222',
        label: '+222',
    },
    {
        desc: 'Mauritius',
        emoji: '🇲🇺',
        short: 'MU',
        value: '+230',
        label: '+230',
    },
    {
        desc: 'Mayotte',
        emoji: '🇾🇹',
        short: 'YT',
        value: '+262',
        label: '+262',
    },
    {
        desc: 'Mexico',
        emoji: '🇲🇽',
        short: 'MX',
        value: '+52',
        label: '+52',
    },
    {
        desc: 'Micronesia, Federated States of Micronesia',
        emoji: '🇫🇲',
        short: 'FM',
        value: '+691',
        label: '+691',
    },
    {
        desc: 'Moldova',
        emoji: '🇲🇩',
        short: 'MD',
        value: '+373',
        label: '+373',
    },
    {
        desc: 'Monaco',
        emoji: '🇲🇨',
        short: 'MC',
        value: '+377',
        label: '+377',
    },
    {
        desc: 'Mongolia',
        emoji: '🇲🇳',
        short: 'MN',
        value: '+976',
        label: '+976',
    },
    {
        desc: 'Montenegro',
        emoji: '🇲🇪',
        short: 'ME',
        value: '+382',
        label: '+382',
    },
    {
        desc: 'Montserrat',
        emoji: '🇲🇸',
        short: 'MS',
        value: '+1664',
        label: '+1664',
    },
    {
        desc: 'Morocco',
        emoji: '🇲🇦',
        short: 'MA',
        value: '+212',
        label: '+212',
    },
    {
        desc: 'Mozambique',
        emoji: '🇲🇿',
        short: 'MZ',
        value: '+258',
        label: '+258',
    },
    {
        desc: 'Myanmar',
        emoji: '🇲🇲',
        short: 'MM',
        value: '+95',
        label: '+95',
    },
    {
        desc: 'Namibia',
        emoji: '🇳🇦',
        short: 'NA',
        value: '+264',
        label: '+264',
    },
    {
        desc: 'Nauru',
        emoji: '🇳🇷',
        short: 'NR',
        value: '+674',
        label: '+674',
    },
    {
        desc: 'Nepal',
        emoji: '🇳🇵',
        short: 'NP',
        value: '+977',
        label: '+977',
    },
    {
        desc: 'Netherlands',
        emoji: '🇳🇱',
        short: 'NL',
        value: '+31',
        label: '+31',
    },
    {
        desc: 'Netherlands Antilles',
        emoji: '',
        short: 'AN',
        value: '+599',
        label: '+599',
    },
    {
        desc: 'New Caledonia',
        emoji: '🇳🇨',
        short: 'NC',
        value: '+687',
        label: '+687',
    },
    {
        desc: 'New Zealand',
        emoji: '🇳🇿',
        short: 'NZ',
        value: '+64',
        label: '+64',
    },
    {
        desc: 'Nicaragua',
        emoji: '🇳🇮',
        short: 'NI',
        value: '+505',
        label: '+505',
    },
    {
        desc: 'Niger',
        emoji: '🇳🇪',
        short: 'NE',
        value: '+227',
        label: '+227',
    },
    {
        desc: 'Nigeria',
        emoji: '🇳🇬',
        short: 'NG',
        value: '+234',
        label: '+234',
    },
    {
        desc: 'Niue',
        emoji: '🇳🇺',
        short: 'NU',
        value: '+683',
        label: '+683',
    },
    {
        desc: 'Norfolk Island',
        emoji: '🇳🇫',
        short: 'NF',
        value: '+672',
        label: '+672',
    },
    {
        desc: 'Northern Mariana Islands',
        emoji: '🇲🇵',
        short: 'MP',
        value: '+1670',
        label: '+1670',
    },
    {
        desc: 'Norway',
        emoji: '🇳🇴',
        short: 'NO',
        value: '+47',
        label: '+47',
    },
    {
        desc: 'Oman',
        emoji: '🇴🇲',
        short: 'OM',
        value: '+968',
        label: '+968',
    },
    {
        desc: 'Pakistan',
        emoji: '🇵🇰',
        short: 'PK',
        value: '+92',
        label: '+92',
    },
    {
        desc: 'Palau',
        emoji: '🇵🇼',
        short: 'PW',
        value: '+680',
        label: '+680',
    },
    {
        desc: 'Palestinian Territory, Occupied',
        emoji: '🇵🇸',
        short: 'PS',
        value: '+970',
        label: '+970',
    },
    {
        desc: 'Panama',
        emoji: '🇵🇦',
        short: 'PA',
        value: '+507',
        label: '+507',
    },
    {
        desc: 'Papua New Guinea',
        emoji: '🇵🇬',
        short: 'PG',
        value: '+675',
        label: '+675',
    },
    {
        desc: 'Paraguay',
        emoji: '🇵🇾',
        short: 'PY',
        value: '+595',
        label: '+595',
    },
    {
        desc: 'Peru',
        emoji: '🇵🇪',
        short: 'PE',
        value: '+51',
        label: '+51',
    },
    {
        desc: 'Philippines',
        emoji: '🇵🇭',
        short: 'PH',
        value: '+63',
        label: '+63',
    },
    {
        desc: 'Pitcairn',
        emoji: '🇵🇳',
        short: 'PN',
        value: '+64',
        label: '+64',
    },
    {
        desc: 'Poland',
        emoji: '🇵🇱',
        short: 'PL',
        value: '+48',
        label: '+48',
    },
    {
        desc: 'Portugal',
        emoji: '🇵🇹',
        short: 'PT',
        value: '+351',
        label: '+351',
    },
    {
        desc: 'Puerto Rico',
        emoji: '🇵🇷',
        short: 'PR',
        value: '+1939',
        label: '+1939',
    },
    {
        desc: 'Qatar',
        emoji: '🇶🇦',
        short: 'QA',
        value: '+974',
        label: '+974',
    },
    {
        desc: 'Romania',
        emoji: '🇷🇴',
        short: 'RO',
        value: '+40',
        label: '+40',
    },
    {
        desc: 'Russia',
        emoji: '🇷🇺',
        short: 'RU',
        value: '+7',
        label: '+7',
    },
    {
        desc: 'Rwanda',
        emoji: '🇷🇼',
        short: 'RW',
        value: '+250',
        label: '+250',
    },
    {
        desc: 'Reunion',
        emoji: '🇷🇪',
        short: 'RE',
        value: '+262',
        label: '+262',
    },
    {
        desc: 'Saint Barthelemy',
        emoji: '🇧🇱',
        short: 'BL',
        value: '+590',
        label: '+590',
    },
    {
        desc: 'Saint Helena, Ascension and Tristan Da Cunha',
        emoji: '🇸🇭',
        short: 'SH',
        value: '+290',
        label: '+290',
    },
    {
        desc: 'Saint Kitts and Nevis',
        emoji: '🇰🇳',
        short: 'KN',
        value: '+1869',
        label: '+1869',
    },
    {
        desc: 'Saint Lucia',
        emoji: '🇱🇨',
        short: 'LC',
        value: '+1758',
        label: '+1758',
    },
    {
        desc: 'Saint Martin',
        emoji: '🇲🇫',
        short: 'MF',
        value: '+590',
        label: '+590',
    },
    {
        desc: 'Saint Pierre and Miquelon',
        emoji: '🇵🇲',
        short: 'PM',
        value: '+508',
        label: '+508',
    },
    {
        desc: 'Saint Vincent and the Grenadines',
        emoji: '🇻🇨',
        short: 'VC',
        value: '+1784',
        label: '+1784',
    },
    {
        desc: 'Samoa',
        emoji: '🇼🇸',
        short: 'WS',
        value: '+685',
        label: '+685',
    },
    {
        desc: 'San Marino',
        emoji: '🇸🇲',
        short: 'SM',
        value: '+378',
        label: '+378',
    },
    {
        desc: 'Sao Tome and Principe',
        emoji: '🇸🇹',
        short: 'ST',
        value: '+239',
        label: '+239',
    },
    {
        desc: 'Saudi Arabia',
        emoji: '🇸🇦',
        short: 'SA',
        value: '+966',
        label: '+966',
    },
    {
        desc: 'Senegal',
        emoji: '🇸🇳',
        short: 'SN',
        value: '+221',
        label: '+221',
    },
    {
        desc: 'Serbia',
        emoji: '🇷🇸',
        short: 'RS',
        value: '+381',
        label: '+381',
    },
    {
        desc: 'Seychelles',
        emoji: '🇸🇨',
        short: 'SC',
        value: '+248',
        label: '+248',
    },
    {
        desc: 'Sierra Leone',
        emoji: '🇸🇱',
        short: 'SL',
        value: '+232',
        label: '+232',
    },
    {
        desc: 'Singapore',
        emoji: '🇸🇬',
        short: 'SG',
        value: '+65',
        label: '+65',
    },
    {
        desc: 'Slovakia',
        emoji: '🇸🇰',
        short: 'SK',
        value: '+421',
        label: '+421',
    },
    {
        desc: 'Slovenia',
        emoji: '🇸🇮',
        short: 'SI',
        value: '+386',
        label: '+386',
    },
    {
        desc: 'Solomon Islands',
        emoji: '🇸🇧',
        short: 'SB',
        value: '+677',
        label: '+677',
    },
    {
        desc: 'Somalia',
        emoji: '🇸🇴',
        short: 'SO',
        value: '+252',
        label: '+252',
    },
    {
        desc: 'South Africa',
        emoji: '🇿🇦',
        short: 'ZA',
        value: '+27',
        label: '+27',
    },
    {
        desc: 'South Sudan',
        emoji: '🇸🇸',
        short: 'SS',
        value: '+211',
        label: '+211',
    },
    {
        desc: 'South Georgia and the South Sandwich Islands',
        emoji: '🇬🇸',
        short: 'GS',
        value: '+500',
        label: '+500',
    },
    {
        desc: 'Spain',
        emoji: '🇪🇸',
        short: 'ES',
        value: '+34',
        label: '+34',
    },
    {
        desc: 'Sri Lanka',
        emoji: '🇱🇰',
        short: 'LK',
        value: '+94',
        label: '+94',
    },
    {
        desc: 'Sudan',
        emoji: '🇸🇩',
        short: 'SD',
        value: '+249',
        label: '+249',
    },
    {
        desc: 'Suridesc',
        emoji: '🇸🇷',
        short: 'SR',
        value: '+597',
        label: '+597',
    },
    {
        desc: 'Svalbard and Jan Mayen',
        emoji: '🇸🇯',
        short: 'SJ',
        value: '+47',
        label: '+47',
    },
    {
        desc: 'Swaziland',
        emoji: '🇸🇿',
        short: 'SZ',
        value: '+268',
        label: '+268',
    },
    {
        desc: 'Sweden',
        emoji: '🇸🇪',
        short: 'SE',
        value: '+46',
        label: '+46',
    },
    {
        desc: 'Switzerland',
        emoji: '🇨🇭',
        short: 'CH',
        value: '+41',
        label: '+41',
    },
    {
        desc: 'Syrian Arab Republic',
        emoji: '🇸🇾',
        short: 'SY',
        value: '+963',
        label: '+963',
    },
    {
        desc: 'Taiwan',
        emoji: '🇹🇼',
        short: 'TW',
        value: '+886',
        label: '+886',
    },
    {
        desc: 'Tajikistan',
        emoji: '🇹🇯',
        short: 'TJ',
        value: '+992',
        label: '+992',
    },
    {
        desc: 'Tanzania, United Republic of Tanzania',
        emoji: '🇹🇿',
        short: 'TZ',
        value: '+255',
        label: '+255',
    },
    {
        desc: 'Thailand',
        emoji: '🇹🇭',
        short: 'TH',
        value: '+66',
        label: '+66',
    },
    {
        desc: 'Timor-Leste',
        emoji: '🇹🇱',
        short: 'TL',
        value: '+670',
        label: '+670',
    },
    {
        desc: 'Togo',
        emoji: '🇹🇬',
        short: 'TG',
        value: '+228',
        label: '+228',
    },
    {
        desc: 'Tokelau',
        emoji: '🇹🇰',
        short: 'TK',
        value: '+690',
        label: '+690',
    },
    {
        desc: 'Tonga',
        emoji: '🇹🇴',
        short: 'TO',
        value: '+676',
        label: '+676',
    },
    {
        desc: 'Trinidad and Tobago',
        emoji: '🇹🇹',
        short: 'TT',
        value: '+1868',
        label: '+1868',
    },
    {
        desc: 'Tunisia',
        emoji: '🇹🇳',
        short: 'TN',
        value: '+216',
        label: '+216',
    },
    {
        desc: 'Turkey',
        emoji: '🇹🇷',
        short: 'TR',
        value: '+90',
        label: '+90',
    },
    {
        desc: 'Turkmenistan',
        emoji: '🇹🇲',
        short: 'TM',
        value: '+993',
        label: '+993',
    },
    {
        desc: 'Turks and Caicos Islands',
        emoji: '🇹🇨',
        short: 'TC',
        value: '+1649',
        label: '+1649',
    },
    {
        desc: 'Tuvalu',
        emoji: '🇹🇻',
        short: 'TV',
        value: '+688',
        label: '+688',
    },
    {
        desc: 'Uganda',
        emoji: '🇺🇬',
        short: 'UG',
        value: '+256',
        label: '+256',
    },
    {
        desc: 'Ukraine',
        emoji: '🇺🇦',
        short: 'UA',
        value: '+380',
        label: '+380',
    },
    {
        desc: 'United Arab Emirates',
        emoji: '🇦🇪',
        short: 'AE',
        value: '+971',
        label: '+971',
    },
    {
        desc: 'United Kingdom',
        emoji: '🇬🇧',
        short: 'GB',
        value: '+44',
        label: '+44',
    },
    {
        desc: 'United States',
        emoji: '🇺🇸',
        short: 'US',
        value: '+1',
        label: '+1',
    },
    {
        desc: 'Uruguay',
        emoji: '🇺🇾',
        short: 'UY',
        value: '+598',
        label: '+598',
    },
    {
        desc: 'Uzbekistan',
        emoji: '🇺🇿',
        short: 'UZ',
        value: '+998',
        label: '+998',
    },
    {
        desc: 'Vanuatu',
        emoji: '🇻🇺',
        short: 'VU',
        value: '+678',
        label: '+678',
    },
    {
        desc: 'Venezuela, Bolivarian Republic of Venezuela',
        emoji: '🇻🇪',
        short: 'VE',
        value: '+58',
        label: '+58',
    },
    {
        desc: 'Vietnam',
        emoji: '🇻🇳',
        short: 'VN',
        value: '+84',
        label: '+84',
    },
    {
        desc: 'Virgin Islands, British',
        emoji: '🇻🇬',
        short: 'VG',
        value: '+1284',
        label: '+1284',
    },
    {
        desc: 'Virgin Islands, U.S.',
        emoji: '🇻🇮',
        short: 'VI',
        value: '+1340',
        label: '+1340',
    },
    {
        desc: 'Wallis and Futuna',
        emoji: '🇼🇫',
        short: 'WF',
        value: '+681',
        label: '+681',
    },
    {
        desc: 'Yemen',
        emoji: '🇾🇪',
        short: 'YE',
        value: '+967',
        label: '+967',
    },
    {
        desc: 'Zambia',
        emoji: '🇿🇲',
        short: 'ZM',
        value: '+260',
        label: '+260',
    },
    {
        desc: 'Zimbabwe',
        emoji: '🇿🇼',
        short: 'ZW',
        value: '+263',
        label: '+263',
    },
];

export const __PhoneData = phone.map((i) => ({
    ...i,
    value: i.value + '|' + i.short,
    newLabel: i.emoji + ' ' + i.value,
}));
