export const __BestsLang = {
    title: 'برترین های تورنتو',
    desc: 'شما می‌توانید برندهای مورد نظر خود را جستجو کرده و در دسته‌بندی‌های مختلف به هر برند امتیاز مثبت یا منفی بدهید. به این صورت نظرات شما به دیگر کاربران کمک می‌کند تا انتخاب بهتری داشته باشند.',
    placeholder: 'جستجو در بین برندها ..',
    bests: 'برترین ها',
    descTitle: 'درباره برند :',
    quickAccess: 'دسترسی سریع:',
    seeMore: 'مشاهده بیشتر',
    rank: (e: number) => `رتبه ${e}`,
    review: 'نظر شما ...',
    reviewDesc: 'شما همچنین میتوانید علاوه بر رای خود، نظر خود را نیز در رابطه با این برند ثبت کنید',
    voteSuccess: 'رای شما با موفقیت ثبت شد',
    aboutBrand: 'درباره برند',
};
